import { defineAsyncComponent } from 'vue';
import type { IconLib } from './types';

export const iconLib: IconLib = {
  aufgaben: defineAsyncComponent(() => import('./Icons/Aufgaben.vue')),
  auftraege: defineAsyncComponent(async () => import('./Icons/Auftraege.vue')),
  buergschaften: defineAsyncComponent(() => import('./Icons/Buergschaften.vue')),
  projekte: defineAsyncComponent(() => import('./Icons/Projekte.vue')),
  vertraege: defineAsyncComponent(() => import('./Icons/Vertraege.vue')),
  texte: defineAsyncComponent(() => import('./Icons/Texte.vue')),
  nutzerRechte: defineAsyncComponent(() => import('./Icons/NutzerRechte.vue')),
  profil: defineAsyncComponent(() => import('./Icons/Profil.vue')),
  hilfe: defineAsyncComponent(() => import('./Icons/Hilfe.vue')),
  zeit: defineAsyncComponent(() => import('./Icons/Zeit.vue')),
  abmelden: defineAsyncComponent(() => import('./Icons/Abmelden.vue')),
  unternehmen: defineAsyncComponent(() => import('./Icons/Unternehmen.vue')),
  stammdaten: defineAsyncComponent(() => import('./Icons/Stammdaten.vue')),
  kreuz: defineAsyncComponent(() => import('./Icons/Kreuz.vue')),
  auge: defineAsyncComponent(() => import('./Icons/Auge.vue')),
  chevronUp: defineAsyncComponent(() => import('./Icons/ChevronUp.vue')),
  chevronDown: defineAsyncComponent(() => import('./Icons/ChevronDown.vue')),
  chevronRight: defineAsyncComponent(() => import('./Icons/ChevronRight.vue')),
  chevronLeft: defineAsyncComponent(() => import('./Icons/ChevronLeft.vue')),
  checkImKreis: defineAsyncComponent(() => import('./Icons/CheckImKreis.vue')),
  ausrufezeichenImKreis: defineAsyncComponent(() => import('./Icons/AusrufezeichenImKreis.vue')),
  ausrufezeichenInRaute: defineAsyncComponent(() => import('./Icons/AusrufezeichenInRaute.vue')),
  iImKreis: defineAsyncComponent(() => import('./Icons/iImKreis.vue')),
  kalender: defineAsyncComponent(() => import('./Icons/Kalender.vue')),
  download: defineAsyncComponent(() => import('./Icons/Download.vue')),
  pfeilRechts: defineAsyncComponent(() => import('./Icons/PfeilRechts.vue')),
  pfeilLinks: defineAsyncComponent(() => import('./Icons/PfeilLinks.vue')),
  pfeilOben: defineAsyncComponent(() => import('./Icons/PfeilOben.vue')),
  pfeilUnten: defineAsyncComponent(() => import('./Icons/PfeilUnten.vue')),
  dreiPunkte: defineAsyncComponent(() => import('./Icons/DreiPunkte.vue')),
  check: defineAsyncComponent(() => import('./Icons/Check.vue')),
  upload: defineAsyncComponent(() => import('./Icons/Upload.vue')),
  reload: defineAsyncComponent(() => import('./Icons/Reload.vue')),
  plus: defineAsyncComponent(() => import('./Icons/Plus.vue')),
  stift: defineAsyncComponent(() => import('./Icons/Stift.vue')),
  lupe: defineAsyncComponent(() => import('./Icons/Lupe.vue')),
  alarm: defineAsyncComponent(() => import('./Icons/Alarm.vue')),
  verifizierteVorlage: defineAsyncComponent(() => import('./Icons/VerifizierteVorlage.vue')),
  hoverInformation: defineAsyncComponent(() => import('./Icons/HoverInformation.vue')),
  ausfuehrung: defineAsyncComponent(() => import('./Icons/Ausfuehrung.vue')),
  anVorauszahlungen: defineAsyncComponent(() => import('./Icons/AnVorauszahlungen.vue')),
  mangelansprueche: defineAsyncComponent(() => import('./Icons/Mangelansprueche.vue')),
  vertragserfuellung: defineAsyncComponent(() => import('./Icons/Vertragserfuellung.vue')),
  admin: defineAsyncComponent(() => import('./Icons/Admin.vue')),
  user: defineAsyncComponent(() => import('./Icons/User.vue')),
  betrachter: defineAsyncComponent(() => import('./Icons/Betrachter.vue')),
  bauarbeiter: defineAsyncComponent(() => import('./Icons/Bauarbeiter.vue')),
  einhorn: defineAsyncComponent(() => import('./Icons/Einhorn.vue')),
  incognito: defineAsyncComponent(() => import('./Icons/Incognito.vue')),
  sprechblasen: defineAsyncComponent(() => import('./Icons/Sprechblasen.vue')),
  checkboxChecked: defineAsyncComponent(() => import('./Icons/CheckboxChecked.vue')),
  checkboxDefault: defineAsyncComponent(() => import('./Icons/CheckboxDefault.vue')),
  checkboxIntermediate: defineAsyncComponent(() => import('./Icons/CheckboxIntermediate.vue')),
  sliders: defineAsyncComponent(() => import('./Icons/Sliders.vue')),
  error: defineAsyncComponent(() => import('./Icons/Error.vue')),
  mitteilung: defineAsyncComponent(() => import('./Icons/Mitteilung.vue')),
  inBearbeitung: defineAsyncComponent(() => import('./Icons/InBearbeitung.vue')),
  siegel: defineAsyncComponent(() => import('./Icons/Siegel.vue')),
  info: defineAsyncComponent(() => import('./Icons/Info.vue')),
  erfolg: defineAsyncComponent(() => import('./Icons/Erfolg.vue')),
  warnung: defineAsyncComponent(() => import('./Icons/Warnung.vue')),
  fehler: defineAsyncComponent(() => import('./Icons/Fehler.vue')),
  schliessen: defineAsyncComponent(() => import('./Icons/Schliessen.vue')),
  knowledge: defineAsyncComponent(() => import('./Icons/Knowledge.vue')),
  spinner: defineAsyncComponent(() => import('./Icons/Spinner.vue')),
};
