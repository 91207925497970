import type { LocaleSchema } from '@/lang/types';

const deLocale: LocaleSchema = {
  auftraggeber: {
    aufgaben: {
      liste: {
        contentHeader: 'Aufgaben',
      },
    },
    buergschaften: {},
    buergschaftsantraege: {},
    buergschaftstexte: {},
    mitteilungen: {},
    nutzer: {},
    projekte: {},
    unternehmen: {},
    vertraege: {},
  },
  buerge: {
    aufgaben: {},
    buergschaften: {},
    buergschaftsantraege: {},
    buergschaftstexte: {},
    nutzer: {},
    unternehmen: {},
    vertraege: {},
  },
  trustlog: {
    aufgaben: {},
    buergschaften: {},
    buergschaftsantraege: {},
    buergschaftstexte: {},
    nutzer: {},
    stammdaten: {},
  },
};

export default deLocale;
